import { uuid4 } from '@sentry/utils'
import { QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { useLayoutEffect, useState } from 'react'
import { getHealth, getUserMe, useGetUserMe } from 'shared/api'
import { ErrorType } from 'shared/api/gen/axios'
import { ApiError, UserMe } from 'shared/api/gen/types'

import { useUserStore } from './useUserStore'

export const useGetUserAndSave = (
  options?: Partial<UseQueryOptions<UserMe, ErrorType<ApiError>, UserMe, QueryKey>>
) => {
  const [uuid, setUuid] = useState(uuid4())

  const queryMe = useGetUserMe(
    {},
    {
      query: {
        refetchOnWindowFocus: false,
        retry: false,
        ...options,
        queryFn(data) {
          setUuid(uuid4())
          void getHealth({ 'X-Request-ID': uuid }, data.signal)

          return getUserMe({ 'X-Request-ID': uuid }, data.signal)
        },
      },
    }
  )

  const { setUser } = useUserStore()
  const { isSuccess, data } = queryMe

  useLayoutEffect(() => {
    isSuccess && setUser(data)
  }, [data, isSuccess, setUser])

  return queryMe
}
