// eslint-disable-next-line import/no-named-as-default
import { uuid4 } from '@sentry/utils'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import get from 'lodash/fp/get'
import { ENV } from 'shared/configs/env'

import { getAuth } from './model/userAuth'

const AUTH_HEADER = 'Authorization'
const REQUEST_ID_HEADER = 'X-Request-ID'
const baseURL = `${ENV.BASE_URL ?? ''}`

const AXIOS_INSTANCE = axios.create({
  baseURL: baseURL + '/api/user',
})

export const authInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return AXIOS_INSTANCE({ ...config, timeout: 45000 }).then(get('data'))
}

AXIOS_INSTANCE.interceptors.request.use((request) => {
  request.headers.set(AUTH_HEADER, getAuth()?.accessToken)

  if (!request.headers.get(REQUEST_ID_HEADER)) request.headers.set(REQUEST_ID_HEADER, uuid4())

  return request
})

export type ErrorType<Error> = AxiosError<Error>
