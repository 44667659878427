import { t } from 'i18next'
import { action, computed, makeAutoObservable, observable } from 'mobx'
import { UserMe } from 'shared/api/gen/types'
import { z } from 'zod'

export class UserStore {
  @observable private _user: UserMe | null = null

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  @action public clearUser = () => {
    this._user = null
  }

  @action public setUser = (user: UserMe) => {
    this._user = user
  }

  get user() {
    return this._user
  }

  @computed get userPolygons(): string[] {
    if (!this._user?.areas) {
      return []
    }

    const polygons: string[] = []

    this._user.areas.forEach((area) => {
      area.geoJson.features.forEach((feature) => {
        try {
          const polygon = z
            .string({ message: t('Errors.Validation.WrongTypePolygon') })
            .parse(feature.properties?.description)

          polygons.push(polygon)
        } catch (err) {
          console.error(err)
        }
      })
    })

    return polygons
  }
}
