import { Box, CircularProgress, Stack, styled } from '@mui/material'
import { captureException } from '@sentry/react'
import { useGetUserAndSave, useHandleAuthError } from 'entities/user'
import { ApiErrorPage } from 'pages/error-pages'
import { useCallback, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import { baseNameForDelivery, RoutersPathsWithRoot } from 'shared/configs/router-consts'
import PagesSkeletons from 'shared/ui/PagesSkeletons'

const FixedBox = styled(Box)(() => ({
  position: 'fixed',
  display: 'flex',
  top: 0,
  left: 0,
  height: '100%',
  width: '100vw',
  zIndex: 100000000,
}))

const Root = () => {
  const { pathname } = useLocation()
  const { isStale, isFetched } = useGetUserAndSave()
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  useHandleAuthError()

  useEffect(() => {
    const handleOnline = () => setIsOffline(false)
    const handleOffline = () => setIsOffline(true)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const check = useCallback(
    () =>
      pathname.search(
        new RegExp(`${baseNameForDelivery}${RoutersPathsWithRoot.root.url}.`, 'g')
      ) === -1,
    [pathname]
  )

  if (isOffline) {
    return (
      <Stack minHeight="100vh" alignItems="center" justifyContent="center">
        <CircularProgress size={60} />
      </Stack>
    )
  }

  return (
    <>
      {!isStale && !isFetched && (
        <FixedBox>
          <PagesSkeletons />
        </FixedBox>
      )}

      <ScrollRestoration />
      <Outlet />

      {check() && <Navigate to={'delivery/order'} />}
    </>
  )
}

export const RootRoute = () => (
  <ErrorBoundary onError={(err) => captureException(err)} fallback={<ApiErrorPage />}>
    <Root />
  </ErrorBoundary>
)
